@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://use.typekit.net/ymr2lmr.css');

:root {
  --primaryColor: #e95d00;
  --primaryColorUnselec: #e95d00;
  --formColor: #f9eee8;
  --darkTextColor: #313030;
  --borderColor: rgba(48, 48, 48, 0.25);
  --shyTextColor: rgba(48, 48, 48, 0.55);
  --imageMargin: 6px;
  --conversationTopMargin: 85px;
}

*:focus {
  outline: none;
}

body,
html {
  margin: 0;
  height: 100%;
  font-family: 'sofia-pro', 'helvetica neue', helvetica, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  font-weight: 700;
}

a:visited,
a:link {
  color: var(--primaryColor);
}

h1,
h2,
h3 {
  font-family: 'josefin sans';
}

h2 {
  font-size: 1.2rem;
  margin-top: 2rem;
}

h1 {
  font-size: 2.75rem;
  line-height: 3.25rem;
}

.small {
  font-size: 0.8rem;
}
#logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  width: 30px;
}
#logo.landing {
  position: absolute;
  left: 20px;
  top: 20px;
}

hr {
  border: none;
  height: 1px;
  background-color: rgba(48, 48, 48, 0.25);
}

textarea {
  font-family: 'sofia-pro', 'helvetica neue', helvetica, monospace;
  height: 300px;
  padding: 10px;
  padding: 15px 19px;
  color: white;
  border: 1px solid #aaa;
  border-radius: 7px;
  box-sizing: border-box;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.05);
  color: black;
  width: 100%;
}

#loggedInWrapper {
  margin: auto;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  #logo {
    position: unset;
  }
  #loggedInWrapper {
    padding: 20px;
    margin-top: 75px;
    text-align: left;
  }
}

.content {
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
  width: calc(76vw + var(--imageMargin) * 8);
  position: relative;
  height: inherit;
}

#loginWrapper {
  margin: auto;
  margin-top: 100px;
  max-width: 500px;
}

#controls {
  display: flex;
  justify-content: space-between;
}

.loginError {
  color: orange;
  font-weight: 700;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
.route404 {
  margin: auto;
  margin-top: 100px;
  font-size: 100px;
  text-align: center;
  font-weight: 100;
  color: var(--primaryColor);
}

.fullscreen {
  z-index: 200;
  min-height: 100vh;
  background-color: white;
  position: absolute;
  width: 100%;
  text-align: left;
}

#user-controls {
  position: absolute;
  top: 20px;
  left: 20px;
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 70%);
  border-radius: 25px;
  z-index: 10;
}

.user-controls-button {
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 70%);
  font-size: 15px;
  padding: 7px;
  background-color: rgba(200, 200, 200, 0.75);
  color: white;
  cursor: pointer;
  border-radius: 25px;
}

.label {
  padding: 5px 7px 3px 7px;
  background: #333;
  color: white;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 3px;
  display: inline;
}
.label.success {
  background: green;
}
.label.fail {
  background: brown;
}

/* Hide number input arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrows Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
