#matchList {
  height: calc(100% - 135px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.matchListItem {
  border: none;
  background: none;
  padding: 0;
  font-size: 1rem;
  color: var(--darkTextColor) !important;
  font-weight: 400;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

.matchListMediaItem {
  overflow: hidden;
  width: 93vw;
  height: 61vw;
  margin: var(--imageMargin);
  background: #eee;
  border-radius: 20px;
}

@media only screen and (min-width: 768px) {
  #matchList {
    height: calc(100% - 70px);
  }

  .matchListItem {
    flex-direction: row;
    align-items: initial;
    justify-content: left;
  }
  .matchListMediaItem {
    width: 300px;
    height: 200px;
  }
}

.matchListItem img {
  border-radius: 12px;
}

.matchListItem .info {
  text-align: left;
}
.matchListItem .details div {
  display: inline;
  margin-right: 10px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.pinIcon {
  margin-right: 5px;
  color: var(--primaryColor);
}
.location {
  color: var(--shyTextColor);
  margin-bottom: 3px;
}
