#matchControls {
  position: fixed;
  margin-top: 50px;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 30px 0px rgb(0 0 0 / 30%);
  background: var(--formColor);
  z-index: 201;
  display: flex;
  justify-content: space-around;
  padding: 15px 25px;
  box-sizing: border-box;
  color: var(--darkTextColor);
  align-items: center;
}

.youAnswered {
  margin-top: -5px;
  margin-bottom: 5px;
  font-weight: 700;
}

#mediaWrapperMobile {
  width: 100%;
  min-height: 30vh;
  background-color: #eee;
  position: relative;
}

#details {
  margin-bottom: 20px;
}

#details div {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
  font-size: 1.2rem;
}

#description {
  white-space: pre-wrap;
  margin-bottom: 30px;
}

#features,
#want,
#misc-flat-data {
  margin-top: 25px;
  margin-bottom: 25px;
}

.feature {
  font-weight: 400;
  color: var(--darkTextColor);
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

#misc-flat-data {
  width: 100%;
}

.flat-data-item {
  padding: 1px 3px 3px 3px;
  display: flex;
  justify-content: space-between;
}

.flat-data-item:nth-of-type(odd) {
  background-color: #eee;
}

.coverLabel {
  padding: 2px 7px 3px 7px;
  background: #333;
  color: white;
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 3px;
  box-shadow: 1px 1px 0px black;
}

.bottomRight {
  top: unset;
  left: unset;
  right: 10px;
  bottom: 10px;
}

@media only screen and (min-width: 768px) {
  #matchControls {
    border-top: none;
    position: unset;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    margin-top: 20px;
    min-width: 200px;

    flex-direction: column-reverse;
    float: right;
    background-color: white;
  }
  #matchControls button {
    margin: 10px 0;
    width: 100%;
  }
  .feature {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .feature div {
    margin-right: 20px;
  }
  #want {
    margin-bottom: 100px;;
  }
}

.mediaOverviewItem {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow: hidden;
  width: 100vw;
  height: 19vw;
  margin: var(--imageMargin);
  background: #eee;
}
