#blog {
    max-width: 600px;
    margin:auto;
    margin-top: 100px;
    padding:25px;
    padding-bottom:150px
}
#date {
    color:gray
}
#intro{
    font-size: 1.25rem;
}

img{
    width:100%;
    
}
.img-text{
    color:gray;
    font-style: italic;
    margin-top:0px;
    font-size:0.85em;
}

h1{
    font-size:2rem;
    line-height:2.5rem;
}