.conversation {
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;
  border-bottom: 1px solid var(--borderColor);
  padding: 20px 0 15px 15px;
  color: var(--darkTextColor) !important;
}

.conversation.active {
  background: #dcf2f9;
}

.conversation.unread {
  background: #ffe9c6;
}

.conversation .lastMessage {
  font-size: 0.8rem;
  font-weight: 400;
}
.conversation h2 {
  margin: 0;
  padding: 0;
}

#inboxDesktop {
  display: flex;
}

#messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: 0px 15px;
  flex: 1 1 auto;
}

#conversation {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: calc(100vh - var(--conversationTopMargin));
}

#conversationHeader {
  width: 100%;
  background: white;
  border-bottom: 1px solid var(--borderColor);
  margin-left: 10px;
}
#conversation h2 {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
#conversationFooter {
  width: 100%;
  bottom: 0px;
  width: 100%;
  background: white;
  padding: 0 10px;
  box-sizing: border-box;
  /* border-top: 1px solid var(--borderColor); */
}

#conversationFooter input {
  background: #F3F3F5;
}

@media only screen and (min-width: 768px) {
  #conversationFooter {
    padding: 0;
    margin-left: 10px;
  }
  #conversationHeader {
    margin-left: 0;
  }
  #conversationHeader h2 {
    margin-left: 20px;
    margin-bottom: 15px;
  }
}

.messageWrapper {
  margin-bottom: 20px;
}
.message {
  border-radius: 15px;
  background-color: lightgray;
  padding: 7px 12px 9px 12px;
}
.messageWrapper.mine {
  align-self: flex-end;
  max-width: 70%;
}
.messageWrapper .sender {
  margin: 0 5px;
}
.messageWrapper.mine .sender {
  text-align: right;
}
.messageWrapper.mine .message {
  background-color: lightblue;
}
.messageWrapper .sender {
  font-size: 0.8rem;
  margin-bottom: 2px;
}
