#mediaWrapperDesktop {
  overflow-y: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

#mediaWrapperDesktop #cover {
  width: calc(38vw + var(--imageMargin) * 2);
  height: calc(38vw + var(--imageMargin) * 2);
  background: #eee;
  box-sizing: border-box;
  margin: var(--imageMargin);
  overflow: hidden;
}

#flatDesktopWrapper {
  width: calc(76vw + var(--imageMargin) * 8);
  margin-top: 35px;
}
#flatDesktopDetailsWrapper {
  padding: 7px;
  display: flex;
}

/* Flex grid */

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow: hidden;
  width: 19vw;
  height: 19vw;
  margin: var(--imageMargin);
  background: #eee;
}
/* Flex grid end */
