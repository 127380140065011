#stepDescription {
  margin-bottom: 30px;
}

.mediaWrapper {
  width: 100%;
  background-color: #eee;
  display: flex;
  animation-name: keyframes1;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

.dragHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

@keyframes keyframes1 {
  0% {
    transform: rotate(-0.5deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0.5deg);
    animation-timing-function: ease-out;
  }
}

video {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 300px;
  width: auto;
  max-width: 400px;
}

.editFlat {
  margin-top: 30px;
  max-width: 400px;
}

#editFlatHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
