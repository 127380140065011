@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
@import url(https://use.typekit.net/ymr2lmr.css);
:root {
  --primaryColor: #e95d00;
  --primaryColorUnselec: #e95d00;
  --formColor: #f9eee8;
  --darkTextColor: #313030;
  --borderColor: rgba(48, 48, 48, 0.25);
  --shyTextColor: rgba(48, 48, 48, 0.55);
  --imageMargin: 6px;
  --conversationTopMargin: 85px;
}

*:focus {
  outline: none;
}

body,
html {
  margin: 0;
  height: 100%;
  font-family: 'sofia-pro', 'helvetica neue', helvetica, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  font-weight: 700;
}

a:visited,
a:link {
  color: #e95d00;
  color: var(--primaryColor);
}

h1,
h2,
h3 {
  font-family: 'josefin sans';
}

h2 {
  font-size: 1.2rem;
  margin-top: 2rem;
}

h1 {
  font-size: 2.75rem;
  line-height: 3.25rem;
}

.small {
  font-size: 0.8rem;
}
#logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  width: 30px;
}
#logo.landing {
  position: absolute;
  left: 20px;
  top: 20px;
}

hr {
  border: none;
  height: 1px;
  background-color: rgba(48, 48, 48, 0.25);
}

textarea {
  font-family: 'sofia-pro', 'helvetica neue', helvetica, monospace;
  height: 300px;
  padding: 10px;
  padding: 15px 19px;
  color: white;
  border: 1px solid #aaa;
  border-radius: 7px;
  box-sizing: border-box;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.05);
  color: black;
  width: 100%;
}

#loggedInWrapper {
  margin: auto;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  #logo {
    position: unset;
  }
  #loggedInWrapper {
    padding: 20px;
    margin-top: 75px;
    text-align: left;
  }
}

.content {
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
  width: calc(76vw + 6px * 8);
  width: calc(76vw + var(--imageMargin) * 8);
  position: relative;
  height: inherit;
}

#loginWrapper {
  margin: auto;
  margin-top: 100px;
  max-width: 500px;
}

#controls {
  display: flex;
  justify-content: space-between;
}

.loginError {
  color: orange;
  font-weight: 700;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
.route404 {
  margin: auto;
  margin-top: 100px;
  font-size: 100px;
  text-align: center;
  font-weight: 100;
  color: #e95d00;
  color: var(--primaryColor);
}

.fullscreen {
  z-index: 200;
  min-height: 100vh;
  background-color: white;
  position: absolute;
  width: 100%;
  text-align: left;
}

#user-controls {
  position: absolute;
  top: 20px;
  left: 20px;
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 70%);
  border-radius: 25px;
  z-index: 10;
}

.user-controls-button {
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 70%);
  font-size: 15px;
  padding: 7px;
  background-color: rgba(200, 200, 200, 0.75);
  color: white;
  cursor: pointer;
  border-radius: 25px;
}

.label {
  padding: 5px 7px 3px 7px;
  background: #333;
  color: white;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 3px;
  display: inline;
}
.label.success {
  background: green;
}
.label.fail {
  background: brown;
}

/* Hide number input arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrows Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#landing {
  height: 100vh
}

#footer {
  background: rgba(0, 0, 0, 0.1);
  padding: 30px;
}

#hero {
  height: 100%;
  overflow: hidden;
  position: relative;
}

#hero-img {
  width: 150%;
  height: 150%;
  object-position: 80% 50%;
  object-fit: cover;
  content: url("https://res.cloudinary.com/lyamotlya/image/upload/w_3000/q_50/v1611072830/landing/hero-mobile.jpg");
}


#hero-text {
  text-align: center;
  position: absolute;
  top: 20%;
  margin: 5%;
  color: var(--darkTextColor);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#register-interest {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hero .signup {
  margin-top: 15px;
  width: 100%;
}

#hero .login {
  position: absolute;
  top: 2%;
  right: 2%;
  width: auto;
}

#hero input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#hero h1 {
  font-size: 2.2em;
  font-weight: 700;
  line-height: 2.2rem;
  margin: 0;
}
#hero p {
  font-weight: 200;
  line-height: 1.5rem;
  font-size: 1.25rem;
}
#blog-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  align-items: center;
}
#blog-preview a {
  display: inherit;
}
.blog-preview-entry {
  margin: 5px 0;
  width: 90%;
  margin: 10px auto;
  /*display: flex;*/
  flex-direction: row;
}
.blog-preview-entry img {
  width: 100%;
  margin-right: 20px;
  border-radius: 7px;
}
.blog-preview-entry p {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

#terms {
  font-size: 0.85em;
  text-align: left;
  margin-top: 10px;
  display: flex;
  align-items: baseline;
}
#terms input {
  width: auto !important;
  margin-right: 10px !important;
  border-radius: 0;
  flex:none;
}

#about {
  background:#F7F7F9;
  padding:20px;
}
#about div{
  max-width: 768px;
  margin:auto;
}

@media only screen and (min-width: 768px) {
  #hero-img {
    content: url("https://res.cloudinary.com/lyamotlya/image/upload/w_3000/q_50/v1611072830/landing/hero.jpg");
  }
  #hero h1 {
    line-height: 3.5rem;
    font-size: 3.2rem;
  }
  #hero-text {
    text-align: left;
    left: 10%;
    width: 90%;
    max-width: 600px;
  }
  #register-interest {
    display: flex;
    flex-direction: row;
  }
  #hero .signup {
    width: auto;
  }
  #hero input {
    display: inline-block;
    margin: 0;
  }
  #blog-preview {
    flex-direction: row;
    align-items: baseline;
    max-width:768px;
  }
  .blog-preview-entry {
    margin: 20px;
    width: 20%;
    flex-direction: column;
  }
  .blog-preview-entry img {
    width: 100%;
  }
  #blog-preview a {
    display: unset;
  }
}

.wrapper {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}

.wrapper button:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-left: none;
}
.wrapper button:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

button {
  padding: 8px;
  border: 0;
  border-left: 1px solid rgb(0 0 0 / 10%);
  width: 100%;
}

.selected {
  background: var(--primaryColor);
  color: white;
}

#blog {
    max-width: 600px;
    margin:auto;
    margin-top: 100px;
    padding:25px;
    padding-bottom:150px
}
#date {
    color:gray
}
#intro{
    font-size: 1.25rem;
}

img{
    width:100%;
    
}
.img-text{
    color:gray;
    font-style: italic;
    margin-top:0px;
    font-size:0.85em;
}

h1{
    font-size:2rem;
    line-height:2.5rem;
}
#matchControls {
  position: fixed;
  margin-top: 50px;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 30px 0px rgb(0 0 0 / 30%);
  background: var(--formColor);
  z-index: 201;
  display: flex;
  justify-content: space-around;
  padding: 15px 25px;
  box-sizing: border-box;
  color: var(--darkTextColor);
  align-items: center;
}

.youAnswered {
  margin-top: -5px;
  margin-bottom: 5px;
  font-weight: 700;
}

#mediaWrapperMobile {
  width: 100%;
  min-height: 30vh;
  background-color: #eee;
  position: relative;
}

#details {
  margin-bottom: 20px;
}

#details div {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
  font-size: 1.2rem;
}

#description {
  white-space: pre-wrap;
  margin-bottom: 30px;
}

#features,
#want,
#misc-flat-data {
  margin-top: 25px;
  margin-bottom: 25px;
}

.feature {
  font-weight: 400;
  color: var(--darkTextColor);
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

#misc-flat-data {
  width: 100%;
}

.flat-data-item {
  padding: 1px 3px 3px 3px;
  display: flex;
  justify-content: space-between;
}

.flat-data-item:nth-of-type(odd) {
  background-color: #eee;
}

.coverLabel {
  padding: 2px 7px 3px 7px;
  background: #333;
  color: white;
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 3px;
  box-shadow: 1px 1px 0px black;
}

.bottomRight {
  top: unset;
  left: unset;
  right: 10px;
  bottom: 10px;
}

@media only screen and (min-width: 768px) {
  #matchControls {
    border-top: none;
    position: unset;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    margin-top: 20px;
    min-width: 200px;

    flex-direction: column-reverse;
    float: right;
    background-color: white;
  }
  #matchControls button {
    margin: 10px 0;
    width: 100%;
  }
  .feature {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .feature div {
    margin-right: 20px;
  }
  #want {
    margin-bottom: 100px;;
  }
}

.mediaOverviewItem {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  overflow: hidden;
  width: 100vw;
  height: 19vw;
  margin: var(--imageMargin);
  background: #eee;
}

#mediaWrapperDesktop {
  overflow-y: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

#mediaWrapperDesktop #cover {
  width: calc(38vw + var(--imageMargin) * 2);
  height: calc(38vw + var(--imageMargin) * 2);
  background: #eee;
  box-sizing: border-box;
  margin: var(--imageMargin);
  overflow: hidden;
}

#flatDesktopWrapper {
  width: calc(76vw + var(--imageMargin) * 8);
  margin-top: 35px;
}
#flatDesktopDetailsWrapper {
  padding: 7px;
  display: flex;
}

/* Flex grid */

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  overflow: hidden;
  width: 19vw;
  height: 19vw;
  margin: var(--imageMargin);
  background: #eee;
}
/* Flex grid end */


#stepDescription {
  margin-bottom: 30px;
}

.mediaWrapper {
  width: 100%;
  background-color: #eee;
  display: flex;
  -webkit-animation-name: keyframes1;
          animation-name: keyframes1;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.dragHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

@-webkit-keyframes keyframes1 {
  0% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

  50% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes keyframes1 {
  0% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

  50% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

video {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 300px;
  width: auto;
  max-width: 400px;
}

.editFlat {
  margin-top: 30px;
  max-width: 400px;
}

#editFlatHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.menuItem{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray; 
  padding: 12px;
  max-width: 280px;
  margin: auto;
  color: #313030
}
.conversation {
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;
  border-bottom: 1px solid var(--borderColor);
  padding: 20px 0 15px 15px;
  color: var(--darkTextColor) !important;
}

.conversation.active {
  background: #dcf2f9;
}

.conversation.unread {
  background: #ffe9c6;
}

.conversation .lastMessage {
  font-size: 0.8rem;
  font-weight: 400;
}
.conversation h2 {
  margin: 0;
  padding: 0;
}

#inboxDesktop {
  display: flex;
}

#messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: 0px 15px;
  flex: 1 1 auto;
}

#conversation {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: calc(100vh - var(--conversationTopMargin));
}

#conversationHeader {
  width: 100%;
  background: white;
  border-bottom: 1px solid var(--borderColor);
  margin-left: 10px;
}
#conversation h2 {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
#conversationFooter {
  width: 100%;
  bottom: 0px;
  width: 100%;
  background: white;
  padding: 0 10px;
  box-sizing: border-box;
  /* border-top: 1px solid var(--borderColor); */
}

#conversationFooter input {
  background: #F3F3F5;
}

@media only screen and (min-width: 768px) {
  #conversationFooter {
    padding: 0;
    margin-left: 10px;
  }
  #conversationHeader {
    margin-left: 0;
  }
  #conversationHeader h2 {
    margin-left: 20px;
    margin-bottom: 15px;
  }
}

.messageWrapper {
  margin-bottom: 20px;
}
.message {
  border-radius: 15px;
  background-color: lightgray;
  padding: 7px 12px 9px 12px;
}
.messageWrapper.mine {
  align-self: flex-end;
  max-width: 70%;
}
.messageWrapper .sender {
  margin: 0 5px;
}
.messageWrapper.mine .sender {
  text-align: right;
}
.messageWrapper.mine .message {
  background-color: lightblue;
}
.messageWrapper .sender {
  font-size: 0.8rem;
  margin-bottom: 2px;
}

#matchList {
  height: calc(100% - 135px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.matchListItem {
  border: none;
  background: none;
  padding: 0;
  font-size: 1rem;
  color: var(--darkTextColor) !important;
  font-weight: 400;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

.matchListMediaItem {
  overflow: hidden;
  width: 93vw;
  height: 61vw;
  margin: var(--imageMargin);
  background: #eee;
  border-radius: 20px;
}

@media only screen and (min-width: 768px) {
  #matchList {
    height: calc(100% - 70px);
  }

  .matchListItem {
    flex-direction: row;
    align-items: initial;
    justify-content: left;
  }
  .matchListMediaItem {
    width: 300px;
    height: 200px;
  }
}

.matchListItem img {
  border-radius: 12px;
}

.matchListItem .info {
  text-align: left;
}
.matchListItem .details div {
  display: inline;
  margin-right: 10px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.pinIcon {
  margin-right: 5px;
  color: var(--primaryColor);
}
.location {
  color: var(--shyTextColor);
  margin-bottom: 3px;
}

.wantFeature {
  margin-right: 20px;
}

#header {
  padding: 10px 15px 7px 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
#headerContent {
  display: flex;
  justify-content: space-between;
  width: calc(76vw + var(--imageMargin) * 8);
  margin: auto;
}

#menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--darkTextColor);
  border-top: 1px solid #999;
  background: white;
  z-index: 100;
}

#menu a {
  color: var(--darkTextColor);
}

.item {
  text-align: center;
  font-weight: 700;
}

.text {
  font-size: 0.7rem;
  opacity: 0.5;
}

@media only screen and (min-width: 768px) {
  #menu {
    position: unset;
    background: 0;
    display: inline-block;
    width: auto;
    border: none;
    padding: 0;
  }
  #menu a {
    display: inline-block;
    margin: 10px;
  }
  #menu .icon {
    display: none;
  }
  .text {
    font-weight: 700;
    color: black;
    font-size: 1rem;
    opacity: 1;
  }
}

