#header {
  padding: 10px 15px 7px 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
#headerContent {
  display: flex;
  justify-content: space-between;
  width: calc(76vw + var(--imageMargin) * 8);
  margin: auto;
}

#menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--darkTextColor);
  border-top: 1px solid #999;
  background: white;
  z-index: 100;
}

#menu a {
  color: var(--darkTextColor);
}

.item {
  text-align: center;
  font-weight: 700;
}

.text {
  font-size: 0.7rem;
  opacity: 0.5;
}

@media only screen and (min-width: 768px) {
  #menu {
    position: unset;
    background: 0;
    display: inline-block;
    width: auto;
    border: none;
    padding: 0;
  }
  #menu a {
    display: inline-block;
    margin: 10px;
  }
  #menu .icon {
    display: none;
  }
  .text {
    font-weight: 700;
    color: black;
    font-size: 1rem;
    opacity: 1;
  }
}
