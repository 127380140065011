.wrapper {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}

.wrapper button:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-left: none;
}
.wrapper button:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

button {
  padding: 8px;
  border: 0;
  border-left: 1px solid rgb(0 0 0 / 10%);
  width: 100%;
}

.selected {
  background: var(--primaryColor);
  color: white;
}
