#landing {
  height: 100vh
}

#footer {
  background: rgba(0, 0, 0, 0.1);
  padding: 30px;
}

#hero {
  height: 100%;
  overflow: hidden;
  position: relative;
}

#hero-img {
  width: 150%;
  height: 150%;
  object-position: 80% 50%;
  object-fit: cover;
  content: url("https://res.cloudinary.com/lyamotlya/image/upload/w_3000/q_50/v1611072830/landing/hero-mobile.jpg");
}


#hero-text {
  text-align: center;
  position: absolute;
  top: 20%;
  margin: 5%;
  color: var(--darkTextColor);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#register-interest {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hero .signup {
  margin-top: 15px;
  width: 100%;
}

#hero .login {
  position: absolute;
  top: 2%;
  right: 2%;
  width: auto;
}

#hero input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#hero h1 {
  font-size: 2.2em;
  font-weight: 700;
  line-height: 2.2rem;
  margin: 0;
}
#hero p {
  font-weight: 200;
  line-height: 1.5rem;
  font-size: 1.25rem;
}
#blog-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  align-items: center;
}
#blog-preview a {
  display: inherit;
}
.blog-preview-entry {
  margin: 5px 0;
  width: 90%;
  margin: 10px auto;
  /*display: flex;*/
  flex-direction: row;
}
.blog-preview-entry img {
  width: 100%;
  margin-right: 20px;
  border-radius: 7px;
}
.blog-preview-entry p {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

#terms {
  font-size: 0.85em;
  text-align: left;
  margin-top: 10px;
  display: flex;
  align-items: baseline;
}
#terms input {
  width: auto !important;
  margin-right: 10px !important;
  border-radius: 0;
  flex:none;
}

#about {
  background:#F7F7F9;
  padding:20px;
}
#about div{
  max-width: 768px;
  margin:auto;
}

@media only screen and (min-width: 768px) {
  #hero-img {
    content: url("https://res.cloudinary.com/lyamotlya/image/upload/w_3000/q_50/v1611072830/landing/hero.jpg");
  }
  #hero h1 {
    line-height: 3.5rem;
    font-size: 3.2rem;
  }
  #hero-text {
    text-align: left;
    left: 10%;
    width: 90%;
    max-width: 600px;
  }
  #register-interest {
    display: flex;
    flex-direction: row;
  }
  #hero .signup {
    width: auto;
  }
  #hero input {
    display: inline-block;
    margin: 0;
  }
  #blog-preview {
    flex-direction: row;
    align-items: baseline;
    max-width:768px;
  }
  .blog-preview-entry {
    margin: 20px;
    width: 20%;
    flex-direction: column;
  }
  .blog-preview-entry img {
    width: 100%;
  }
  #blog-preview a {
    display: unset;
  }
}
